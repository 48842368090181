/* eslint-disable react/prop-types */
import React from 'react'

const Lid = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="z-30"
    viewBox="0 0 605.91 244.46"
  >
    <g id="Lid" data-name="Lid">
      <g id="e1a9485c-0f43-4303-9e0d-5e4a75181bc4">
        <path
          d="M306.6,137.05l-6.38,1.35c-2.71.57-66.83,13.51-99-19.63C185.94,103,175.91,83.82,173,64.77c-3.15-20.38,2-38.31,14.58-50.48s30.64-16.78,50.91-13c19,3.55,37.84,14.18,53.11,29.94h0c32.12,33.15,17.18,96.83,16.52,99.52ZM212.39,22.66a28.51,28.51,0,0,0-10.45,6.4c-7.62,7.38-10.66,18.93-8.56,32.52,2.3,14.89,10.36,30.1,22.69,42.82,19.17,19.78,57.79,17,73.28,14.86,2.57-15.41,6.61-53.93-12.56-73.71h0c-12.33-12.72-27.28-21.25-42.09-24C226.4,20,218.79,20.4,212.39,22.66Z"
          fill="#3b82f6"
        />
        <path
          d="M297.14,137.33,295.69,131c-.61-2.71-14.45-66.64,18.24-99.22h0c15.54-15.5,34.59-25.8,53.62-29,20.33-3.44,38.33,1.49,50.68,13.87S435.44,47,431.94,67.34c-3.28,19-13.65,38-29.19,53.53-32.67,32.53-96.55,18.53-99.26,17.91ZM393.31,24.58c-6.36-2.38-14-2.93-22.29-1.52-14.86,2.51-30,10.78-42.5,23.29h0c-19.51,19.45-16.14,58-13.83,73.49,15.45,2.35,54,5.86,73.53-13.6,12.55-12.51,20.87-27.57,23.43-42.42,2.34-13.55-.5-25.15-8-32.66a28.57,28.57,0,0,0-10.35-6.58Z"
          fill="#3b82f6"
        />
        <rect
          x="31.48"
          y="134.29"
          width="542.96"
          height="110.17"
          opacity="0.1"
          style={{ isolation: 'isolate' }}
        />
        <rect y="126.42" width="605.91" height="110.17" fill="#f472b6" />
        <rect
          x="251.81"
          y="126.42"
          width="102.3"
          height="110.17"
          fill="#3b82f6"
        />
      </g>
    </g>
  </svg>
)

export { Lid }
