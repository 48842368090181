/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { Link, graphql } from 'gatsby'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useWindowSize from 'react-use/lib/useWindowSize'

import SEO from '../components/seo'
import { Lid } from '../../content/assets/lid'
import { Box } from '../../content/assets/box'
import { Test } from '../../content/assets/test'
import Celebrate from '../components/celebrate'

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { scrollYProgress } = useViewportScroll()
  const { height } = useWindowSize()

  console.log(height)

  const presentLidScroll = useTransform(
    scrollYProgress,
    [0, 0.35, 0.5],
    [0, -(height * 0.75), -(height * 1.5)],
  )
  const presentBaseScroll = useTransform(
    scrollYProgress,
    [0, 0.35, 0.5],
    [0, -(height * 0.75), height],
  )
  const presentOpacity = useTransform(scrollYProgress, [0.4, 0.6], [1, 0])

  const testScroll = useTransform(
    scrollYProgress,
    [0, 0.35, 0.7],
    [0, -(height * 0.75), height * 0.3],
  )
  const testScale = useTransform(
    scrollYProgress,
    [0, 0.35, 0.75, 0.8],
    [1, 1, 2, 2],
  )

  const weArePregnantOpacity = useTransform(scrollYProgress, [0.5, 0.6], [0, 1])

  return (
    <div className="w-full bg-gray-900">
      <SEO title={siteTitle} keywords={[`baby`, `boy`, `girl`, `longhurst`]} />
      <motion.div
        className="fixed z-50 h-2 w-full bg-blue-500"
        style={{
          scaleX: scrollYProgress,
        }}
      ></motion.div>
      <div className="h-screen flex flex-col justify-center">
        <h1 className="text-center font-bold text-4xl md:text-8xl uppercase">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-400 to-blue-500">
            Special Delivery!
          </span>
        </h1>
        <p className="px-6 text-center text-white text-xl md:text-3xl">
          We have little present that we would like to share with you...
        </p>
      </div>
      <div className="h-screen" />
      <div className="relative h-screen flex flex-col justify-end items-center">
        <motion.div
          className="absolute top-0 w-80 h-20 z-30"
          style={{
            y: presentLidScroll,
            opacity: presentOpacity,
          }}
        >
          <Lid />
        </motion.div>
        <motion.div
          className="absolute top-0 mt-20 w-72 h-64 z-20"
          style={{
            y: presentBaseScroll,
            opacity: presentOpacity,
          }}
        >
          <Box />
        </motion.div>
        <motion.div
          className="absolute top-0 mt-20 w-auto h-44 z-0"
          style={{
            y: testScroll,
            scale: testScale,
          }}
        >
          <Test />
        </motion.div>
        <motion.div
          className="absolute top-0 mt-20 ml-4 md:ml-6 text-5xl md:text-6xl font-medium text-white uppercase"
          style={{
            y: testScroll,
            opacity: weArePregnantOpacity,
          }}
        >
          We<span className="inline-block w-24"></span>are
        </motion.div>
        <motion.div
          className="absolute top-0 mt-40 ml-2 md:ml-4 text-5xl md:text-8xl text-center font-medium text-white uppercase"
          style={{
            y: testScroll,
            opacity: weArePregnantOpacity,
          }}
        >
          Preg<span className="inline-block w-24"></span>nant
        </motion.div>
        <Celebrate className="mt-44" />
      </div>
      <div className="h-screen flex flex-col justify-center items-center">
        <h2 className="px-6 text-center text-3xl font-semibold text-white">
          To find out if it is a <span className="text-blue-500">boy</span> or{' '}
          <span className="text-pink-400">girl</span> you must choose your own
          adventure...
        </h2>
        <div className="mt-20 w-full md:w-1/2 flex flex-row justify-center gap-x-14 md:justify-around">
          <Link
            className="w-24 md:w-44 inline-flex justify-center items-center px-6 py-3 border text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-l from-blue-400 to-pink-400 hover:via-pink-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 uppercase"
            to="/easy"
          >
            Easy
          </Link>
          <Link
            className="w-24 md:w-44 inline-flex justify-center items-center px-6 py-3 border text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-blue-400 to-pink-400 hover:via-pink-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 uppercase"
            to="/hard"
          >
            Hard
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomePage

export const homePageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
