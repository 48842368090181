/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import Reward from 'react-rewards'

const Celebrate = () => {
  const reward = useRef(null)

  const onCelebrate = () => {
    reward.current.rewardMe()
  }
  return (
    <Reward ref={reward} config={{ spread: 180 }} type="confetti">
      <button
        className="inline-flex justify-center items-center px-6 py-3 border text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-blue-400 to-pink-400 hover:via-pink-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 uppercase z-40"
        onClick={onCelebrate}
      >
        Press me to celebrate! 🎉
      </button>
    </Reward>
  )
}

export default Celebrate
