/* eslint-disable react/prop-types */
import React from 'react'

const Box = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="shadow-md z-20"
    viewBox="0 0 542.96 440.67"
  >
    <g id="Box" data-name="Box">
      <g id="e1a9485c-0f43-4303-9e0d-5e4a75181bc4">
        <rect width="542.96" height="440.67" fill="#f472b6" />
        <rect x="220.33" width="102.3" height="440.67" fill="#3b82f6" />
      </g>
    </g>
  </svg>
)

export { Box }
