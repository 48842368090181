/* eslint-disable react/prop-types */
import React from 'react'

const Test = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto h-44 w-auto z-0"
    viewBox="0 0 67.79 328.26"
  >
    <g id="Text" data-name="Test">
      <g id="e1a9485c-0f43-4303-9e0d-5e4a75181bc4">
        <path
          d="M66.58,204.77c-.23-4.39-.47-8.82-.72-13.34l-.32-6.18c-1.42-28.86-2.9-58.71.21-90.31l1.78-15.67a25,25,0,0,0-5.8-20A32.64,32.64,0,0,0,55,53V9.49C55,4.26,51.13,0,46.38,0h-25C16.66,0,12.8,4.26,12.8,9.49V53.11a35,35,0,0,0-7,6.53A26.84,26.84,0,0,0,0,76.16a15.87,15.87,0,0,0,.36,4L2.12,93.65c4.19,34.43,2.05,66.22,0,96.95v.45c0,.42-.25,3.9-.48,7.38s-.36,5.31-.42,6.34a3.91,3.91,0,0,0,0,.64c0,.54,0,.64,0,.69l-.05.88C.36,218.77,0,228.71,0,236.51v.65c0,25.49,7.49,69,21.55,84.42,4,4.37,8.45,6.68,13.25,6.68h0c4.7-.43,9.29-3,13.27-7.33,12.13-13.3,19.76-46.45,19.45-84.42,0-10.16-.4-20.39-.94-30.91C66.61,205,66.58,204.77,66.58,204.77ZM8.85,191.06c2.08-31.06,4.24-63.16,0-98.25L7.1,79.53H60.7L59,94.23c-3.16,32.14-1.67,62.24-.23,91.35l.31,6.23c.26,4.53.5,9,.73,13.38-.65,4-10.6,9.45-25.94,9.45S8.46,209.1,8,205.1c.09-1.41.25-3.81.41-6.22C8.85,191.44,8.85,191.37,8.85,191.06ZM21.42,6.76h25c.88,0,1.86,1.12,1.86,2.73V49.91a46.62,46.62,0,0,0-28.69,0V9.49C19.55,7.88,20.54,6.76,21.42,6.76Zm-10.31,57a30.15,30.15,0,0,1,6.83-6.07A40.2,40.2,0,0,1,50,57.79a26.05,26.05,0,0,1,6.49,5.77,18.54,18.54,0,0,1,4.27,9.22H7.06A20.4,20.4,0,0,1,11.11,63.8Zm32,252.58c-2.82,3.1-5.89,4.88-8.58,5.14-3.22,0-6.12-2.44-8-4.49-12.74-14-19.78-56.09-19.78-79.87v-.65c0-6,.22-13.27.64-21.7,5.87,4.07,15.35,6.62,26.5,6.62s20.53-2.53,26.4-6.56c.31,7.32.51,14.52.51,21.66C61.1,272.36,54,304.45,43.11,316.38Z"
          fill="#f472b6"
        />
        <path
          d="M32,185.73h3.84A16.21,16.21,0,0,0,52,169.54v-45.4A16.2,16.2,0,0,0,35.82,108H32a16.2,16.2,0,0,0-16.19,16.19v45.4A16.21,16.21,0,0,0,32,185.73Zm-9.43-61.59A9.43,9.43,0,0,1,32,114.72h3.84a9.43,9.43,0,0,1,9.42,9.42v45.4A9.44,9.44,0,0,1,35.82,179H32a9.44,9.44,0,0,1-9.43-9.42Z"
          fill="#3b82f6"
        />
        <path
          d="M29.2,159h9.39a3.38,3.38,0,0,0,0-6.76H29.2a3.38,3.38,0,0,0,0,6.76Z"
          fill="#fff"
        />
        <path
          d="M29.2,135.29h9.39a3.38,3.38,0,0,0,0-6.76H29.2a3.38,3.38,0,0,0,0,6.76Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

export { Test }
